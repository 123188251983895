import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Input, Button } from "../components";

interface MenuOptionsPageProps {
  data: {
    file: {
      childImageSharp: {
        fluid: any;
      };
    };
  };
}

const MenuOptionsPage = (props: MenuOptionsPageProps) => (
  <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
    <Img
      fluid={props.data.file.childImageSharp.fluid}
      alt=""
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#D3D9DD",
        flex: "35%",
      }}
    />
    <div
      style={{
        padding: 20,
        flex: "65%",
      }}
    >
      <h2
        style={{
          fontFamily: "Raleway",
          fontSize: "26pt",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        DAY GUESTS ONLY
      </h2>
      <div className="meal-details-list">
        <MenuOptionDetails
          name="MENU 1"
          starter="Tomato, Basil & Mozzarella Salad"
          starterSide="with Pesto Dressing"
          main="Chicken Supreme"
          mainSide="with Colcannon Mash, Fine Beans & Cider Sauce"
          dessert="Warm Sticky Toffee Pudding"
          dessertSide="with Toffee Sauce & Vanilla Ice Cream"
        />
        <MenuOptionDetails
          name="MENU 2"
          starter="Grilled Halloumi"
          starterSide="with Courgette & Chilli Salad"
          main="Wild Mushroom Risotto"
          mainSide="with Truffle & a Parmesan Crisp"
          dessert="Warm Sticky Toffee Pudding"
          dessertSide="with Toffee Sauce & Vanilla Ice Cream"
        />
        <KidsMenuOptionDetails
          name="KIDS MENU"
          starter="Garlic Bread"
          mains={["Cheese & Tomato Pizza", "Chicken Nuggets & Chips"]}
          dessert="Chocolate Brownie"
          dessertSide="with Vanilla Ice Cream"
        />
      </div>
      <MenuOptionsForm />
    </div>
  </div>
);

export const pageQuery = graphql`
  query MenuOptionsQuery {
    file: file(relativePath: { eq: "table.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default MenuOptionsPage;

type MenuOptionDetailsProps = {
  name: string;
  starter: string;
  starterSide?: string;
  main: string;
  mainSide?: string;
  dessert: string;
  dessertSide?: string;
};

const MenuOptionDetails = (props: MenuOptionDetailsProps) => {
  return (
    <div className="meal-details-container">
      <h4 style={{ fontSize: "20pt", fontWeight: 600, marginTop: "30px" }}>
        {props.name}
      </h4>
      <p
        style={{
          fontSize: "16pt",
          fontWeight: 100,
          color: "#999",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        Starter
      </p>
      <p
        style={{
          fontSize: "20pt",
          fontWeight: 400,
          marginBottom: "10px",
        }}
      >
        {props.starter}
      </p>
      {props.starterSide && (
        <p
          style={{
            fontSize: "16pt",
            fontWeight: 200,
            fontStyle: "italic",
          }}
        >
          {props.starterSide}
        </p>
      )}
      <p
        style={{
          fontSize: "16pt",
          fontWeight: 100,
          color: "#999",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        Main
      </p>
      <p
        style={{
          fontSize: "20pt",
          fontWeight: 400,
          marginBottom: "10px",
        }}
      >
        {props.main}
      </p>
      {props.mainSide && (
        <p
          style={{
            fontSize: "16pt",
            fontWeight: 200,
            fontStyle: "italic",
          }}
        >
          {props.mainSide}
        </p>
      )}
      <p
        style={{
          fontSize: "16pt",
          fontWeight: 100,
          color: "#999",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        Dessert
      </p>
      <p
        style={{
          fontSize: "20pt",
          fontWeight: 400,
          marginBottom: "10px",
        }}
      >
        {props.dessert}
      </p>
      {props.dessertSide && (
        <p
          style={{
            fontSize: "16pt",
            fontWeight: 200,
            fontStyle: "italic",
          }}
        >
          {props.dessertSide}
        </p>
      )}
    </div>
  );
};

type KidsMenuOptionDetailsProps = {
  name: string;
  starter: string;
  starterSide?: string;
  mains: string[];
  mainSide?: string;
  dessert: string;
  dessertSide?: string;
};

const KidsMenuOptionDetails = (props: KidsMenuOptionDetailsProps) => {
  return (
    <div className="meal-details-container">
      <h4 style={{ fontSize: "20pt", fontWeight: 600, marginTop: "30px" }}>
        {props.name}
      </h4>
      <p
        style={{
          fontSize: "16pt",
          fontWeight: 100,
          color: "#999",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        Starter
      </p>
      <p
        style={{
          fontSize: "20pt",
          fontWeight: 400,
          marginBottom: "10px",
        }}
      >
        {props.starter}
      </p>
      {props.starterSide && (
        <p
          style={{
            fontSize: "16pt",
            fontWeight: 200,
            fontStyle: "italic",
          }}
        >
          {props.starterSide}
        </p>
      )}
      <p
        style={{
          fontSize: "16pt",
          fontWeight: 100,
          color: "#999",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        Main
      </p>
      {props.mains.map((name, idx) => (
        <>
          <p
            style={{
              fontSize: "20pt",
              fontWeight: 400,
              marginBottom: "10px",
            }}
          >
            {name}
          </p>
          {idx < props.mains.length - 1 && (
            <p
              style={{
                fontSize: "20pt",
                fontWeight: 400,
                marginBottom: "5px",
              }}
            >
              OR
            </p>
          )}
        </>
      ))}
      {props.mainSide && (
        <p
          style={{
            fontSize: "16pt",
            fontWeight: 200,
            fontStyle: "italic",
          }}
        >
          {props.mainSide}
        </p>
      )}
      <p
        style={{
          fontSize: "16pt",
          fontWeight: 100,
          color: "#999",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        Dessert
      </p>
      <p
        style={{
          fontSize: "20pt",
          fontWeight: 400,
          marginBottom: "10px",
        }}
      >
        {props.dessert}
      </p>
      {props.dessertSide && (
        <p
          style={{
            fontSize: "16pt",
            fontWeight: 200,
            fontStyle: "italic",
          }}
        >
          {props.dessertSide}
        </p>
      )}
    </div>
  );
};

enum MenuOption {
  Menu1 = "menu 1",
  Menu2 = "menu 2",
  KidsMenu = "kids menu",
}

const MenuOptionsForm = () => {
  const [name, setName] = React.useState("");
  const [additional, setAdditional] = React.useState("");
  const [menuOption, setMenuOption] = React.useState<MenuOption | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const [success, setSuccess] = React.useState<null | string>(null);
  return (
    <form
      name="rsvp"
      style={{
        textAlign: "center",
        padding: 20,
        maxWidth: 500,
        marginLeft: "auto",
        marginRight: "auto",
      }}
      onSubmit={(event) => {
        event.preventDefault();
        setError(null);
        setSuccess(null);
        if (!name) {
          setError("Please fill in your name");
          return;
        }
        if (menuOption === undefined) {
          setError("Please select a menu option");
          return;
        }
        setLoading(true);
        fetch(
          "https://i7btwgv8mg.execute-api.eu-west-1.amazonaws.com/default/weddingFormResponse",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify({
              "form-name": "menu-options",
              name,
              menuOption: menuOption,
              additional,
            }),
          }
        )
          .then((_res) => {
            setLoading(false);
            setName("");
            setMenuOption(undefined);
            setAdditional("");
            setSuccess("Thank you for your submission");
          })
          .catch((_err) => {
            setLoading(false);
            setError("Server error, please try again later");
          });
      }}
    >
      <p
        style={{
          fontFamily: "Raleway",
          fontWeight: 200,
          fontSize: "24pt",
          marginTop: 20,
          lineHeight: "32px",
        }}
      >
        Please submit your choices
      </p>
      <Input
        label="Name"
        id="name"
        value={name}
        onChange={(event) => {
          const { value } = event.target;
          setName(value);
        }}
      />
      <div style={{ textAlign: "left" }}>
        <div
          onClick={() => {
            setMenuOption(MenuOption.Menu1);
          }}
          style={{
            fontFamily: "Raleway",
            fontWeight: 200,
            fontSize: "16pt",
            padding: "20px 0",
          }}
        >
          <input
            type="radio"
            id="menu-option-1"
            name="menu-option-1"
            readOnly
            checked={menuOption === MenuOption.Menu1}
          />
          <label style={{ margin: "10px" }}>Menu Option 1</label>
        </div>
        <div
          onClick={() => {
            setMenuOption(MenuOption.Menu2);
          }}
          style={{
            fontFamily: "Raleway",
            fontWeight: 200,
            fontSize: "16pt",
            padding: "20px 0",
          }}
        >
          <input
            type="radio"
            id="menu-option-2"
            name="menu-option-2"
            readOnly
            checked={menuOption === MenuOption.Menu2}
          />
          <label style={{ margin: "10px" }}>Menu Option 2</label>
        </div>
        <div
          onClick={() => {
            setMenuOption(MenuOption.KidsMenu);
          }}
          style={{
            fontFamily: "Raleway",
            fontWeight: 200,
            fontSize: "16pt",
            padding: "20px 0",
          }}
        >
          <input
            type="radio"
            id="menu-option-kids"
            name="menu-option-kids"
            readOnly
            checked={menuOption === MenuOption.KidsMenu}
          />
          <label style={{ margin: "10px" }}>Kids Menu</label>
        </div>
        <Input
          label="Additional Comments (i.e. Allergies)"
          id="additional"
          value={additional}
          onChange={(event) => {
            const { value } = event.target;
            setAdditional(value);
          }}
        />
      </div>
      <Button disabled={loading}>{loading ? "Loading..." : "Submit"}</Button>
      {success && (
        <p
          style={{
            fontFamily: "Raleway",
            color: "#57BC07",
            fontWeight: 600,
            marginTop: 20,
          }}
        >
          {success}
        </p>
      )}
      {error && (
        <p
          style={{
            fontFamily: "Raleway",
            color: "#CE0000",
            fontWeight: 600,
            marginTop: 20,
          }}
        >
          {error}
        </p>
      )}
    </form>
  );
};
